import { createRef, Component, MutableRefObject } from 'react';

import axios from 'axios';
import * as download from 'downloadjs';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import * as intl from 'react-intl-universal';

import SuccessResponse from 'api/common/responses/SuccessResponse';
import ApiError from 'api/common/types/ApiError';
import GroupApiInstance from 'api/group/GroupApi';
import GroupDetailListItem from 'api/group/types/GroupDetailListItem';
import GroupDetailMapItem from 'api/group/types/GroupDetailMapItem';
import GroupMetricValue from 'api/group/types/GroupMetricValue';
import TablePreferences from 'api/group/types/TablePreferences';
import ProjectsApiInstance from 'api/projects/ProjectsApi';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import StorageKeys from 'constants/StorageKeys';
import { formatDate } from 'helpers/DateFormat';
import { defaultGlobalFilters } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import { getFormattedNumber } from 'helpers/NumberFormat';
import setPageTitle from 'helpers/setPageTitle';
import GroupContainer from 'modules/private/group/components/group-container/GroupContainer';
import NotificationFilterOption from 'modules/private/group/components/group-container/notification-select/NotificationFilterOption';
import GroupMetrics from 'modules/private/group/components/group-metrics/GroupMetrics';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import DateFormatType from 'shared/enums/DateFormatType';
import EventKey from 'shared/enums/EventKey';
import GroupNotification from 'shared/enums/GroupNotification';
import HttpStatus from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import GroupOverviewProps from './GroupOverviewProps';
import GroupOverviewState, {
  NotificationInitStatus,
} from './GroupOverviewState';
import PageQuery, { PageFilters } from './PageQuery';

const projectNavFilters = [
  GroupNotification.UnassignedToFacilitator,
  GroupNotification.UnassignedGroupStatus,
];

class GroupOverview extends Component<GroupOverviewProps, GroupOverviewState> {
  constructor(props: GroupOverviewProps) {
    super(props);
    this.fetchIdRef = createRef();
    const orgId = AuthStorageService.GetItem<string>(
      StorageKeys.OrganizationId
    );

    const search = queryString.parse(props.location.search);
    const viaProjects = Boolean(search.viaProjects);

    this.state = {
      organizationId: orgId,
      viaProjects,
      notifications: [],
      notificationsStatus: Status.Idle,
      notificationFilters: [],
      notificationInitial: NotificationInitStatus.Idle,
      searchTerm: '',
      showGroupList: true,
      filters: defaultGlobalFilters,
      groupMetricsData: {
        data: Array<GroupMetricValue>(),
        loading: false,
      },
      groupDetailMapData: {
        data: Array<GroupDetailMapItem>(),
        loading: false,
      },
      groupDetailListData: {
        data: Array<GroupDetailListItem>(),
        loading: false,
        error: '',
        pageCount: 0,
        pageSize: 10,
        pageIndex: 0,
        sortBy: [{ id: 'name', desc: false }],
        pagination: { total: 0, page: 1 },
        hiddenColumns: [],
        columnOrder: [],
        allRowIds: [],
        selectedRows: {},
        unselectAllRows: false,
        showManageColsModal: false,
        facilitators: [],
        facilitatorsStatus: Status.Idle,
        facilitatorsError: '',
        groupStatGroupsAssigned: true,
        assignToProjectStatus: Status.Idle,
        assignToProjectError: '',
        assignFacilitatorsStatus: Status.Idle,
        assignFacilitatorsError: '',
        assignGroupsStatusStatus: Status.Idle,
        assignGroupsStatusError: '',
        groupOverviewStatsStatus: Status.Idle,
      },
    };
  }

  componentDidMount(): void {
    const { appContext } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('BTN_GROUPS'));

    const { filters, searchTerm, notificationFilters } = this.state;

    this.loadGroupMetricData(filters);
    this.fetchGroupNotifications(
      notificationFilters,
      filters,
      searchTerm,
      true
    );

    /**
     * fetching all-row ids on mount so users can "Select All" rows
     */
    this.fetchAllGroupIds(filters, searchTerm, notificationFilters);
    this.fetchGroupOverviewStats();
  }

  componentDidUpdate(
    prevProps: GroupOverviewProps,
    prevState: GroupOverviewState
  ): void {
    const {
      filters,
      showGroupList,
      searchTerm,
      groupDetailListData: {
        pageSize,
        pageIndex,
        sortBy,
        assignToProjectStatus,
        assignFacilitatorsStatus,
        assignGroupsStatusStatus,
      },
      notificationFilters,
      notificationsStatus,
      notificationInitial,
    } = this.state;
    const {
      groupDetailListData: {
        pageSize: prevPageSize,
        pageIndex: prevPageIdx,
        sortBy: prevSortBy,
        assignToProjectStatus: prevProjectStatus,
        assignFacilitatorsStatus: prevFacilitatorStatus,
        assignGroupsStatusStatus: prevGroupsStatus,
      },
      notificationInitial: prevNotificationInitial,
    } = prevState;
    const { appContext, location } = this.props;
    const { globalFilters, setExcelReportDownloadCallback } = appContext;

    setExcelReportDownloadCallback(this.excelDownloadCallback);

    if (location.search !== prevProps.location.search) {
      this.handleQueryParamChanged();
    }

    if (notificationsStatus !== Status.Loading) {
      /**
       * this will only be called the first time (only once in component life-cycle)
       * the user comes to group overview; after mount. notificationInitial indicates
       * the immediate render just after fetching notification filters for the first
       * time; ViaProjectsNoFilters means all the notifications has count zero and warning-less
       * ViaGroups means no automatic filter application is needed since visited
       * through side bar groups.
       */
      if (
        notificationInitial !== prevNotificationInitial &&
        (notificationInitial === NotificationInitStatus.ViaProjectsNoFilters ||
          notificationInitial === NotificationInitStatus.ViaGroups)
      ) {
        this.loadGroupDetailListData(
          globalFilters,
          { searchTerm, pageSize, pageIndex, sortBy },
          notificationFilters,
          false
        );
      }

      if (!isEqual(filters, globalFilters)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ filters: globalFilters });
        const { fromDate, toDate } = globalFilters;
        if ((!fromDate && !toDate) || (fromDate && toDate)) {
          this.loadGroupMetricData(globalFilters);

          if (showGroupList) {
            this.loadGroupDetailListData(
              globalFilters,
              { pageSize, pageIndex, sortBy, searchTerm },
              notificationFilters,
              true,
              true
            );
          } else {
            this.loadGroupDetailMapData(
              globalFilters,
              searchTerm,
              notificationFilters
            );
          }
        }
      }

      if (pageSize !== prevPageSize || pageIndex !== prevPageIdx) {
        this.loadGroupDetailListData(
          globalFilters,
          { pageSize, pageIndex, sortBy, searchTerm },
          notificationFilters,
          false
        );
      }
      if (!isEqual(sortBy, prevSortBy)) {
        this.loadGroupDetailListData(
          globalFilters,
          { pageSize, pageIndex, sortBy, searchTerm },
          notificationFilters,
          true
        );

        // assumes table can only be sorted by one attribute at a given time
        const eventLabel = `${String(LabelKeysGA[sortBy[0].id])}:${
          sortBy[0].desc ? 'desc' : 'asc'
        }`;
        sendEventGA(
          CategoryKeysGA.GroupsList,
          ActionKeysGA.SortList,
          eventLabel
        );
      }
      if (!isEqual(notificationFilters, prevState.notificationFilters)) {
        if (showGroupList) {
          this.loadGroupDetailListData(
            globalFilters,
            { pageSize, pageIndex, sortBy, searchTerm },
            notificationFilters,
            true,
            true
          );
        } else {
          this.loadGroupDetailMapData(
            globalFilters,
            searchTerm,
            notificationFilters
          );
        }
      }

      if (!isEqual(prevState.showGroupList, showGroupList)) {
        if (showGroupList) {
          if (searchTerm && searchTerm.length > 0) {
            this.onSearchTermChange(searchTerm);
          } else {
            this.loadGroupDetailListData(
              globalFilters,
              { searchTerm, pageSize, pageIndex, sortBy },
              notificationFilters,
              false
            );
          }
        } else {
          this.loadGroupDetailMapData(
            globalFilters,
            searchTerm,
            notificationFilters
          );
        }
      }

      /**
       * fetches group list whenever user assign project, facilitator, group status
       * to groups to show the updated list
       */
      // prettier-ignore
      if (
        (assignToProjectStatus !== prevProjectStatus && assignToProjectStatus === Status.Success) ||
        (assignFacilitatorsStatus !== prevFacilitatorStatus && assignFacilitatorsStatus === Status.Success) ||
        (assignGroupsStatusStatus !== prevGroupsStatus && assignGroupsStatusStatus === Status.Success)
      ) {
        this.loadGroupDetailListData(
          globalFilters,
          { pageSize, pageIndex, sortBy, searchTerm },
          notificationFilters,
          true,
          true,
          true
        );

        this.loadGroupMetricData(globalFilters);

        /**
         * Fetch new stats to decide whether to show tooltip guide
         */
        this.fetchGroupOverviewStats();
      }
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**  guarantee sequential list api requests */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchIdRef: MutableRefObject<any>;

  /**
   * Fetch group metric data
   *
   * @param filters Currently set global filters
   */
  private async loadGroupMetricData(filters: GlobalFilters): Promise<void> {
    const { appContext } = this.props;
    try {
      this.setState((state) => ({
        groupMetricsData: { ...state.groupMetricsData, loading: true },
      }));
      const result = await GroupApiInstance.GetGroupMetricData(
        filters,
        this.source
      );
      this.setState({
        groupMetricsData: {
          loading: false,
          data: result.items,
        },
      });
    } catch (error) {
      this.setState({
        groupMetricsData: {
          loading: false,
          data: Array<GroupMetricValue>(),
        },
      });
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  }

  /**
   * Fetch group details map data
   *
   * @param filters Currently set global filters
   * @param searchTerm Search value
   * @param notificationFilters Currently selected notification filters
   */
  private async loadGroupDetailMapData(
    filters: GlobalFilters,
    searchTerm: string,
    notificationFilters: string[]
  ): Promise<void> {
    const { appContext } = this.props;
    try {
      this.setState((state) => ({
        groupDetailMapData: { ...state.groupDetailMapData, loading: true },
      }));
      /**
       * Whenever search term is changed, notification filters also
       * needs to be re-fetched to see the updated count;
       */
      const notifications = await this.fetchGroupNotifications(
        notificationFilters,
        filters,
        searchTerm
      );

      /**
       * Send only the valid notifications; notifications which has count > 0
       */
      const validNotifications = notificationFilters.filter((item) => {
        const current = notifications.find((element) => element.value === item);
        return current && current.count > 0;
      });

      const result = await GroupApiInstance.GetGroupMapData(
        filters,
        searchTerm,
        validNotifications,
        this.source
      );
      this.setState({
        groupDetailMapData: {
          loading: false,
          data: result.items,
        },
      });
    } catch (error) {
      this.setState({
        groupDetailMapData: {
          loading: false,
          data: Array<GroupDetailMapItem>(),
        },
      });
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  }

  /**
   * Fetch groups list data
   *
   * @param filters Currently set global filters
   * @param pageFilters Currently set pagination filters
   * @param notificationFilters Currently selected notification filters
   * @param resetPageIndex Whether to reset the page index
   * @param changeSelection Whether to change selection on filter change
   * @param ignoreClearSelection Whether to ignore clearing the selection
   */
  private loadGroupDetailListData = async (
    filters: GlobalFilters,
    pageFilters: PageFilters,
    notificationFilters: string[],
    resetPageIndex = false,
    changeSelection = false,
    ignoreClearSelection = false
  ): Promise<void> => {
    const { appContext } = this.props;
    // Give this fetch an ID
    // eslint-disable-next-line no-plusplus
    const fetchId = ++this.fetchIdRef.current;
    this.setGroupsListData({ loading: true });

    const { pageSize, pageIndex, sortBy, searchTerm } = pageFilters;

    const pageQuery = new PageQuery(
      pageIndex,
      pageSize,
      sortBy,
      searchTerm,
      resetPageIndex
    );

    try {
      /**
       * Whenever search term is changed, notification filters also
       * needs to be re-fetched to see the updated count;
       */
      const notifications = await this.fetchGroupNotifications(
        notificationFilters,
        filters,
        searchTerm
      );

      /**
       * Send only the valid notifications; notifications which has count > 0
       */
      const validNotifications = notificationFilters.filter((item) => {
        const current = notifications.find((element) => element.value === item);
        return current && current.count > 0;
      });

      const result = await GroupApiInstance.GetGroupListData(
        filters,
        pageQuery,
        validNotifications,
        this.source
      );

      /**
       * call all group ids to select items according to global filters
       * when global filters change @param changeSelection dictates weather
       * to get selection from backend- current only when searchTerm changes
       * or notification filters change, assign items, global filter change
       */
      if (changeSelection) {
        await this.setNotificationGroupIdSelection(
          filters,
          searchTerm,
          validNotifications,
          ignoreClearSelection
        );
      }
      /**
       * on a concurrent scenario the counts of list
       * notifications and previously applied notifications
       * may differ; this is a correction for the new counts
       */
      const newNotifications = notifications.map((option) => {
        const count = result.meta.notifications[option.value];
        if (option.count !== count) {
          return { ...option, count };
        }
        return option;
      });

      // Only update the data if this is the latest fetch
      if (fetchId === this.fetchIdRef.current) {
        this.setState({ notifications: newNotifications });
        this.setGroupsListData({
          data: result.items,
          pagination: result.pagination,
          hiddenColumns: result.meta.hiddenColumns,
          columnOrder: result.meta.allColumns,
          pageCount: Math.ceil(result.pagination.total / pageSize),
          loading: false,
          error: null,
          pageIndex: pageQuery.page,
        });
      }
    } catch (error) {
      if (fetchId === this.fetchIdRef.current) {
        if (error instanceof ApiError) {
          this.setGroupsListData({
            error: error.message,
            loading: false,
            data: [],
            pageCount: 0,
            pageIndex: 0,
            pagination: { total: 0, page: 1 },
          });
          if (error.status !== HttpStatus.FORBIDDEN) {
            appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
          }
        } else {
          this.setGroupsListData({
            error: intl.get('ERR_TOAST_GENERIC_ERROR'),
            loading: false,
            data: [],
            pageCount: 0,
            pageIndex: 0,
            pagination: { total: 0, page: 1 },
          });
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      }
    }
  };

  /**
   * Fetch all teh group IDs
   *
   * @param filters Currently set global filters
   * @param searchTerm Search value
   * @param validNotifications Currently selected valid notification filters
   */
  fetchAllGroupIds = async (
    filters: GlobalFilters,
    searchTerm: string,
    validNotifications: string[]
  ): Promise<string[]> => {
    try {
      const { items } = await GroupApiInstance.GetGroupsAllIds(
        filters,
        searchTerm,
        validNotifications,
        this.source
      );

      this.setGroupsListData({ allRowIds: items });
      return items;
    } catch (error) {
      this.setGroupsListData({ allRowIds: [] });
      return [];
    }
  };

  /**
   * Fetch all group IDs free of pagination for the given criteria
   * to be used to select all items in the list.
   *
   * @param filters Currently set global filters
   * @param searchTerm Search value
   * @param validNotifications Currently selected valid notification filters
   */
  setNotificationGroupIdSelection = async (
    filters: GlobalFilters,
    searchTerm: string,
    validNotifications: string[],
    ignoreClearSelection = false
  ): Promise<void> => {
    const items = await this.fetchAllGroupIds(
      filters,
      searchTerm,
      validNotifications
    );

    if (validNotifications.length > 0) {
      const selected = items.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue]: true,
        }),
        {}
      );
      this.setSelectedRows(selected);
    } else {
      if (ignoreClearSelection) {
        return;
      }
      this.setSelectedRows({});
    }

    /**
     * applying filters may increase or reduce the count of
     * all rows, hence setting the current allRowIds for
     * "Select All" functionality
     */
    this.setGroupsListData({ allRowIds: items });
  };

  /**
   * Fetch notifications, checks filters non zero count notifications
   * and sets the notifications and filters
   *
   * @param notificationFilters Currently set notification filters
   * @param filters Currently set global filters
   * @param search Search value
   * @param isInitial Whether it's the first time fetching notification; only on DidMount
   */
  fetchGroupNotifications = async (
    notificationFilters: string[],
    filters: GlobalFilters,
    search?: string,
    isInitial = false
  ): Promise<NotificationFilterOption[]> => {
    const {
      appContext: { setErrorToastText },
    } = this.props;
    const { viaProjects, notifications: prevNotifications } = this.state;
    this.setState({ notificationsStatus: Status.Loading });
    try {
      const notifications = await this.getGroupNotifications(filters, search);
      const newFilters =
        viaProjects && isInitial ? projectNavFilters : notificationFilters;
      let validFilters =
        viaProjects && isInitial ? projectNavFilters : notificationFilters;
      /**
       * if on first time the notifications are being fetched or
       * whenever this method is called where notificationFilters are active
       * checks with newly fetched notification counts and weeds out
       * the notification filters which has count equal to zero
       */
      if (notificationFilters.length > 0 || isInitial) {
        const countZeroNotifications = notifications
          .filter((notification) => notification.isDisabled)
          .map((notification) => notification.value);

        if (countZeroNotifications.length > 0) {
          validFilters = validFilters.filter(
            (item) => !countZeroNotifications.includes(item)
          );
        }
      }

      /**
       * gets the Initial Notification state; one of NotificationInitStatus
       */
      const getNewNotificationState = (): NotificationInitStatus => {
        if (isInitial) {
          if (viaProjects) {
            if (validFilters.length > 0) {
              return NotificationInitStatus.ViaProjectsHasFilters;
            }
            return NotificationInitStatus.ViaProjectsNoFilters;
          }
          return NotificationInitStatus.ViaGroups;
        }
        return NotificationInitStatus.Idle;
      };

      this.setState({
        notifications,
        notificationsStatus: Status.Success,
        notificationFilters: newFilters,
        notificationInitial: getNewNotificationState(),
      });

      return notifications;
    } catch (error) {
      setErrorToastText('ERR_GROUPS_LIST_FETCH_FAILURE_TOAST');
      this.setState({
        notifications: [],
        notificationsStatus: Status.Error,
      });
    }
    return prevNotifications;
  };

  /**
   * Fetch notifications and returns a formatted array of notifications
   *
   * @param filters Currently set global filters
   * @param search Search value
   * @returns {Promise<NotificationFilterOption[]>} Promise of type NotificationFilterOption[]
   */
  getGroupNotifications = async (
    filters: GlobalFilters,
    search?: string
  ): Promise<NotificationFilterOption[]> => {
    const response = await GroupApiInstance.GetGroupNotifications(
      filters,
      this.source,
      search
    );
    if (response.item) {
      const data = response.item;
      const notificationsFormatted = Object.entries(data.notifications).reduce(
        (accumulator, [key, value]) => ({
          ...accumulator,
          [key]: {
            value: key,
            label: intl.get(`LBL_GD_${key}`, {
              c: getFormattedNumber(value ?? 0, false),
            }),
            count: value ?? 0,
            isDisabled: (value ?? 0) === 0,
          },
        }),
        {}
      );
      /**
       * to get the desired sort order in figma
       */
      const notifications = [
        notificationsFormatted[GroupNotification.UnassignedToFacilitator],
        notificationsFormatted[GroupNotification.UnassignedToProject],
        notificationsFormatted[GroupNotification.UnassignedGroupStatus],
      ];
      return notifications;
    }
    return [];
  };

  /**
   * Fetch a list of facilitators for action bar facilitator assign toggle layer
   *
   * @param search Search value
   */
  fetchFacilitators = async (search?: string): Promise<void> => {
    this.setGroupsListData({ facilitatorsStatus: Status.Loading });
    try {
      const response = await SettingsApiInstance.LookupOrganizationFacilitators(
        this.source,
        search
      );
      this.setGroupsListData({
        facilitators: response.items,
        facilitatorsStatus: Status.Success,
        facilitatorsError: '',
      });
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = '';
        }
      }
      this.setGroupsListData({
        facilitatorsStatus: Status.Error,
        facilitatorsError: errorText,
        assignFacilitatorsError: '',
      });
    }
  };

  /**
   * Fetch group overview statistics
   */
  fetchGroupOverviewStats = async (): Promise<void> => {
    const { appContext } = this.props;
    this.setGroupsListData({
      groupOverviewStatsStatus: Status.Loading,
    });
    try {
      const { item } = await GroupApiInstance.GetGroupOverviewStats(
        this.source
      );
      const assignStatus = get(item, `tooltipStatus`)?.find(
        (val) => val.tooltip === 'ASSIGN'
      );

      this.setGroupsListData({
        groupOverviewStatsStatus: Status.Success,
        groupStatGroupsAssigned: assignStatus ? assignStatus.status : true,
      });
    } catch (error) {
      this.setGroupsListData({
        groupOverviewStatsStatus: Status.Error,
        groupStatGroupsAssigned: true,
      });
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Assign a project to a list of groups
   *
   * @param projectId Selected project
   * @param groups Checked groups
   */
  postAssignGroupsToProject = async (
    projectId: string,
    groups: string[]
  ): Promise<void> => {
    const { appContext } = this.props;
    this.setGroupsListData({ assignToProjectStatus: Status.Loading });
    try {
      await ProjectsApiInstance.AssignGroupsToProject(
        projectId,
        groups,
        this.source
      );
      this.setGroupsListData({
        assignToProjectStatus: Status.Success,
        assignToProjectError: '',
      });
      appContext.validateCurrencyToggle(appContext.globalFilters);
      appContext.setSuccessToastText(intl.get('LBL_GD_PROJECT_ASSIGN_SUCCESS'));

      sendEventGA(CategoryKeysGA.GroupsAssign, ActionKeysGA.AssignToProject);
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = '';
        }
        if (error.status === HttpStatus.NOT_FOUND) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      }
      this.setGroupsListData({
        assignToProjectStatus: Status.Error,
        assignToProjectError: errorText,
      });
    }
  };

  /**
   * Assign a facilitator to a list of groups
   *
   * @param facilitator Selected facilitator
   * @param groups Checked groups
   */
  postAssignGroupsFacilitators = async (
    facilitator: string,
    groups: string[]
  ): Promise<void> => {
    const { appContext } = this.props;
    this.setGroupsListData({ assignFacilitatorsStatus: Status.Loading });
    try {
      await GroupApiInstance.AssignFacilitatorsToProject(
        facilitator,
        groups,
        this.source
      );
      this.setGroupsListData({
        assignFacilitatorsStatus: Status.Success,
        assignFacilitatorsError: '',
      });
      appContext.setSuccessToastText(
        intl.get('LBL_GD_FACILITATOR_ASSIGN_SUCCESS')
      );

      sendEventGA(
        CategoryKeysGA.GroupsAssign,
        ActionKeysGA.AssignToFacilitator
      );
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = '';
        }
      }
      this.setGroupsListData({
        assignFacilitatorsStatus: Status.Error,
        assignFacilitatorsError: errorText,
        facilitatorsError: '',
      });
    }
  };

  /**
   * Assign a group status to a list of groups
   *
   * @param statusId Selected status
   * @param groups Checked groups
   */
  postAssignGroupsStatus = async (
    statusId: string,
    groups: string[]
  ): Promise<void> => {
    const { appContext } = this.props;
    this.setGroupsListData({ assignGroupsStatusStatus: Status.Loading });
    try {
      await GroupApiInstance.AssignGroupStatusToGroups(
        statusId,
        groups,
        this.source
      );
      this.setGroupsListData({
        assignGroupsStatusStatus: Status.Success,
        assignGroupsStatusError: '',
      });
      appContext.setSuccessToastText(
        intl.get('LBL_GD_GROUP_STATUS_ASSIGN_SUCCESS')
      );

      sendEventGA(CategoryKeysGA.GroupsAssign, ActionKeysGA.AssignGroupStatus);
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = '';
        }
      }
      this.setGroupsListData({
        assignGroupsStatusStatus: Status.Error,
        assignGroupsStatusError: errorText,
      });
    }
  };

  /**
   * Callback for downloading the excel report
   */
  excelDownloadCallback = async (): Promise<void> => {
    const { appContext } = this.props;
    const { globalFilters } = appContext;

    try {
      const reportTime = new Date();
      const formattedDate = formatDate(reportTime, DateFormatType.BlobRequest);

      const blob = await GroupApiInstance.GetGroupsExcel(
        globalFilters,
        formattedDate,
        this.source
      );

      const dateTime = formatDate(reportTime, DateFormatType.ExcelFilename);
      const fileName = `DreamSaveInsight-Groups-${dateTime}.xlsx`;
      download(blob, fileName);
      // prettier-ignore
      sendEventGA(CategoryKeysGA.GroupsReports, ActionKeysGA.DownloadExcel, fileName);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      }
    }
  };

  /**
   * Update table preferences
   *
   * @param preferences List of user preferences for the groups data table
   */
  putTablePreferences = async (
    preferences: TablePreferences[]
  ): Promise<SuccessResponse> =>
    GroupApiInstance.SetTablePreferences(preferences, this.source);

  setGroupsListData = (
    attributes: Partial<GroupOverviewState['groupDetailListData']>,
    callback?: () => void
  ): void => {
    this.setState(
      (state) => ({
        ...state,
        groupDetailListData: { ...state.groupDetailListData, ...attributes },
      }),
      callback
    );
  };

  /**
   * Sets the current allRowIds state field to selectedRows
   * selecting all available rows (through pagination)
   */
  setSelectAll = (): void => {
    const { allRowIds } = this.state.groupDetailListData;
    const selected = allRowIds.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: true,
      }),
      {}
    );
    this.setSelectedRows(selected);
    sendEventGA(CategoryKeysGA.GroupsList, ActionKeysGA.SelectAllRows);
  };

  /**
   * Unselects all rows
   */
  setUnselectAll = (): void => {
    this.setGroupsListData({ unselectAllRows: true }, () =>
      this.setGroupsListData({ unselectAllRows: false })
    );

    sendEventGA(CategoryKeysGA.GroupsList, ActionKeysGA.UnselectAllRows);
  };

  /**
   * Sets status regarding group status assignment to state
   */
  setAssignGroupsStatusStatus = (status: Status): void =>
    this.setGroupsListData({ assignGroupsStatusStatus: status });

  /**
   * Sets status regarding project assignment to state
   */
  setAssignToProjectStatus = (status: Status): void =>
    this.setGroupsListData({ assignToProjectStatus: status });

  /**
   * Sets status regarding facilitator assignment to state
   */
  setAssignFacilitatorsStatus = (status: Status): void =>
    this.setGroupsListData({ assignFacilitatorsStatus: status });

  /**
   * Toggles manage columns modal
   *
   * @param open Whether the modal is currently open
   */
  toggleModal = (open?: boolean): void => {
    const isOpen = typeof open === 'boolean' ? open : false;
    this.setGroupsListData({ showManageColsModal: isOpen });
  };

  /**
   * Sets hidden columns to state
   *
   * @param hiddenColumns List of hidden columns
   */
  setHiddenColumns = (hiddenColumns: string[]): void => {
    this.setGroupsListData({ hiddenColumns });
  };

  /**
   * Sets selected rows to state
   *
   * @param selectedRows List of selected rows
   */
  setSelectedRows = (selectedRows: { [key: string]: boolean }): void => {
    this.setGroupsListData({ selectedRows });

    // prettier-ignore
    sendEventGA(CategoryKeysGA.GroupsList, ActionKeysGA.SelectRows, `Count: ${Object.keys(selectedRows).length}`);
  };

  /**
   * Sets data params to state
   *
   * @param dataParams Data params
   */
  updateDataParams = (dataParams): void => this.setGroupsListData(dataParams);

  /**
   * Manage search query change and maintain boolean viaProjects
   * to indicate if the user came through projects
   */
  handleQueryParamChanged = (): void => {
    const { location } = this.props;
    const search = queryString.parse(location.search);
    const viaProjects = Boolean(search.viaProjects);
    if (viaProjects) {
      this.setState({ viaProjects });
    } else {
      this.setState({ viaProjects, notificationFilters: [] });
      this.setGroupsListData({ selectedRows: {} });
    }
  };

  /**
   * Handle filter change event
   *
   * @param values Updated filter values
   */
  handleChangeFilters = (values: string[] | null): void => {
    sendEventGA(
      CategoryKeysGA.GroupsNotifications,
      ActionKeysGA.ChangeNotifications
    );
    this.setState({ notificationFilters: values || [] });
  };

  /**
   * Handle view change between list and map
   *
   * @param showGroupList Whether to display the groups list
   */
  onViewChange = (showGroupList: boolean): void => {
    // prettier-ignore
    const gaCategory = showGroupList ? CategoryKeysGA.GroupsList : CategoryKeysGA.GroupsMap;
    sendEventGA(gaCategory, ActionKeysGA.View);

    this.setState({ showGroupList });
  };

  /**
   * Handle search term change event
   *
   * @param searchTerm Current search value
   */
  onSearchTermChange = (searchTerm = ''): void => {
    const {
      showGroupList,
      groupDetailListData: { pageSize, pageIndex, sortBy },
      notificationFilters,
    } = this.state;
    const { appContext } = this.props;
    const { globalFilters } = appContext;

    this.setState({ searchTerm });

    if (showGroupList) {
      this.loadGroupDetailListData(
        globalFilters,
        { pageSize, pageIndex, sortBy, searchTerm },
        notificationFilters,
        true,
        true
      );
    } else {
      this.loadGroupDetailMapData(
        globalFilters,
        searchTerm,
        notificationFilters
      );
    }

    if (searchTerm) {
      // prettier-ignore
      const eventCategory = showGroupList ? CategoryKeysGA.GroupsList : CategoryKeysGA.GroupsMap;
      // prettier-ignore
      sendEventGA(eventCategory, ActionKeysGA.SearchList, `By: ${searchTerm.toUpperCase()}`);
    }
  };

  render(): JSX.Element {
    const {
      searchTerm,
      showGroupList,
      notifications,
      notificationsStatus,
      notificationFilters,
      groupMetricsData,
      groupDetailMapData,
      groupDetailListData,
    } = this.state;

    const groupDetailsListHandlers = {
      fetchData: this.updateDataParams,
      setHiddenColumns: this.setHiddenColumns,
      setSelectedRows: this.setSelectedRows,
      setSelectAll: this.setSelectAll,
      setUnselectAll: this.setUnselectAll,
      toggleColOrderModal: this.toggleModal,
      putTablePreferences: this.putTablePreferences,
      fetchFacilitators: this.fetchFacilitators,
      postAssignGroupsToProject: this.postAssignGroupsToProject,
      postAssignGroupsFacilitators: this.postAssignGroupsFacilitators,
      postAssignGroupsStatus: this.postAssignGroupsStatus,
      setAssignGroupsStatusStatus: this.setAssignGroupsStatusStatus,
      setAssignToProjectStatus: this.setAssignToProjectStatus,
      setAssignFacilitatorsStatus: this.setAssignFacilitatorsStatus,
    };

    const groupListProps = {
      ...groupDetailListData,
      ...groupDetailsListHandlers,
    };

    const { location } = this.props;

    return (
      <div className="content-container">
        <ScrollToTopOnMount />
        <GroupMetrics
          data={groupMetricsData.data}
          loading={groupMetricsData.loading}
        />
        <GroupContainer
          searchTerm={searchTerm}
          showGroupList={showGroupList}
          groupMapData={groupDetailMapData}
          groupListProps={groupListProps}
          filters={notificationFilters}
          notifications={notifications}
          location={location}
          notificationsStatus={notificationsStatus}
          onChangeFilters={this.handleChangeFilters}
          onSearchTermChange={this.onSearchTermChange}
          onViewChange={this.onViewChange}
        />
      </div>
    );
  }
}

export default GroupOverview;
